<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>
    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2 mb-4">{{$t('profile.activities.entry.title')}}</v-card-title>
      <v-card-text v-if="!isEmbeddedInApp">
        {{$t('profile.activities.entry.description')}}
      </v-card-text>

      <v-alert v-if="context && context.claim_events && context.claim_events.length" type="info" class="mx-3">
        <p>{{$t('profile.activities.entry.claim-achievement-instead')}}</p>
        <v-btn v-if="!showClaimEvents" outlined @click="showClaimEvents=true">{{$t('profile.activities.entry.claim-achievement')}}</v-btn>
        <div v-if="showClaimEvents">
          <p>{{$t('app.select-event')}}</p>
          <div v-for="(item, idx) in context.claim_events" :key="idx">
            <v-btn :to="{name: 'activityClaim', params: {eventId: item.event_id, raceId: item.race_id}}">{{ item.event_name }} -> {{item.race_name}}</v-btn>
          </div>

        </div>
      </v-alert>

      <v-stepper v-if="activity" v-model="step" :elevation="0">
        <v-stepper-items>
          <v-stepper-content :step="1" class="pa-0">
            <div class="d-flex align-center flex-column mb-4">
              <h4 class="mb-2">{{$t('profile.activities.edit.select-activity-type')}}</h4>
              <ToggleButtonInput
                v-if="event && allowedActivityTypes && allowedActivityTypes.length > 0"
                v-model="selectedActivityTypeView"
                rounded
                style="margin: 0 auto; flex-grow: unset;"
                :items="[{type:'EVENT', text: $t('app.tracking.view-this-event')}, {type:'ALL', text: $t('shared.all-options')}]"
                />

              <ToggleButtonInput
                v-if="!event && allowedActivityTypes && allowedActivityTypes.length > 0"
                v-model="selectedActivityTypeView"
                rounded
                style="margin: 0 auto; flex-grow: unset;"
                :items="[{type:'MY_EVENTS', text: $t('app.tracking.my-events')}, {type:'ALL', text: $t('shared.all-options')}]"
                />

            </div>
            <v-alert v-if="selectedActivityTypeView === 'EVENT' && event && event.activity_types && event.activity_types.length === 0" type="info" tile>
              {{ $t('app.tracking.activity-types-steps-only') }}
            </v-alert>
            <v-alert v-if="selectedActivityTypeView === 'EVENT' && event && event.activity_types && event.activity_types.length > 0" type="info" tile>
              {{ $t('app.tracking.activity-types-few') }}
            </v-alert>

            <v-list>
              <v-divider />
              <template v-for="(item, idx) in visibleActivityTypes">
                <v-list-item :key="idx" selectable :value="item.type" @click="selectActivityType(item)">
                  <v-list-item-icon>
                    <v-icon style="min-width:30px;" :title="item.text" >{{$helpers.getActivityIcon(item.base_type || item.type)}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name || $helpers.getActivityTypeLabel(item.type) }}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.event_name && !event">{{ $t('shared.for')}}: {{ item.event_name }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>fa-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="`${idx}_sep`" />
              </template>
            </v-list>
            
          </v-stepper-content>
          <v-stepper-content :step="2">

            <h3 class="subtitle py-0">{{$t('profile.activities.entry.activity-details')}}</h3>
            <p>
              <span class="me-1">{{$t('profile.activities.edit.activity-type')}}:</span><strong>{{ activity.type === 'CUSTOM' && selectedActivityType ? selectedActivityType.name : $helpers.getActivityTypeLabel(activity.type) }}</strong>
              <v-btn text color="primary" @click="step=1"><v-icon small class="me-1">fa-pencil</v-icon> {{$t('shared.edit')}}</v-btn>
            </p>
            <p class="text-muted">
              {{$t('profile.activities.entry.tip-time-of-day')}}
            </p>

            <v-alert v-if="error || !valid" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
            <v-form ref="form" v-model="valid" lazy-validation v-if="activity">
                <DateAndTimeWithTimeZonePicker v-model="activity.start" ref="timePicker" :timeZone="timeZoneOlson" :label="$t('profile.activities.entry.activity-date-start')" :timeLabel="$t('profile.activities.entry.activity-time-start')" />
                <v-row>
                <v-col v-if="showDistance" cols="12" sm="6">
                <DistanceTextArea
                  v-model="activity.dist" 
                  icon="fa-ruler"
                  :unit="unitType"
                  :label="$t('profile.activities.entry.distance-label', { unit: unitTypeDiplay, requirement: activityService.requireDistance(activity) ? $t('shared.required') : $t('shared.optional') } )" 
                  :rules="numberRules"
                  >
                </DistanceTextArea>
                  </v-col>
                  <v-col v-if="showElevation || showElevationLoss" cols="12" sm="6">

                    <DistanceTextArea
                      v-if="showElevation && !isDownhill"
                      v-model="computedElevationGain" 
                      icon="fa-mountain"
                      :mode="$helpers.UnitType.ELEVATION"
                      :unit="unitType"
                      :label="$t('profile.activities.entry.elevation-label', {unit: elevationUnitTypeDiplay})" 
                      >
                    </DistanceTextArea>
                    <DistanceTextArea
                      v-if="showElevationLoss"
                      v-model="computedElevationLoss" 
                      icon="fa-mountain"
                      :mode="$helpers.UnitType.ELEVATION"
                      :unit="unitType"
                      :label="$t('profile.activities.entry.elevation-loss-label', {unit: elevationUnitTypeDiplay})" 
                      >
                    </DistanceTextArea>
                  </v-col>
                  <v-col v-if="activity.type == 'STAIR_CLIMBING'" cols="12" sm="6">

                    <DistanceTextArea
                      v-if="activity.type == 'STAIR_CLIMBING'"
                      v-model="activity.custom" 
                      icon="fa-sort-amount-up-alt fa-flip-horizontal"
                      :mode="$helpers.UnitType.NUMBER"
                      :unit="unitType"
                      :label="$t('profile.activities.entry.climbed-stairs')" 
                      >
                    </DistanceTextArea>
                    <p class="text-muted">{{$t('profile.activities.entry.climbed-stairs-tip')}}</p>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="showCustomField">

                    <DistanceTextArea
                      v-model="activity.custom" 
                      icon="fa-tally"
                      :mode="$helpers.UnitType.NUMBER"
                      :unit="unitType"
                      :label="$t('profile.activities.entry.custom')" 
                      :hint="customFieldLabel"
                      />
                  </v-col>
                  <v-col cols="12" sm="6" v-if="showSteps">
                    <DistanceTextArea
                      v-model="activity.steps" 
                      icon="fa-shoe-prints"
                      :mode="$helpers.UnitType.NUMBER"
                      :label="$t('profile.activities.steps')" 
                      :hint="$t('profile.activities.entry.steps-hint')" 
                      :rules="stepsRules"
                      >
                    </DistanceTextArea>
                  </v-col>
                </v-row>
                <DurationTextArea
                  v-if="showDuration"
                  v-model="activity.time_s" 
                  class="mt-4"
                  icon="fa-clock"
                  :label="timeFieldLabel" 
                  >
                </DurationTextArea>
                <div v-if="showNameField">
                  <v-text-field
                    v-model="activity.name"
                    prepend-icon="fa-comment" 
                    :label="$t('profile.activities.entry.name-label')" 
                    />
                </div>

                <br/>
              
                <v-checkbox v-model="addAnotherAfterSave" :label="$t('profile.activities.entry.add-another')" />
                <v-alert v-if="infoMessage" class="mt-4" type="info">
                  <vue-markdown class="markdown" :html="false" :source="infoMessage" />
                  <p>Please note that when you're participating in multiple events, your activity may still count towards your other events.</p>
                </v-alert>
                <div v-if="geoRestrictedRaces && geoRestrictedRaces.length > 0">
                  <h4>Select the areas where your (entire) activity took place</h4>
                  <p>You can view the exact allowed geographical areas on the event page.</p>
                  <div v-for="(item, idx) in geoRestrictedRaces" :key="item.id">
                    <v-checkbox v-model="approvedRaces" multiple :value="item.id" :label="item.name" hide-details/>
                  </div>
                </div>
                <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
                <div v-if="validationError" class="mt-8 mb-8 d-flex">
                  <v-btn :disabled="!valid" color="primary" outlined :loading="$store.getters.isLoading" @click="submit(true)">{{$t('profile.activities.entry.save-anyway')}}</v-btn>
                  <v-spacer/>
                  <v-btn :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('profile.activities.entry.validate-again')}}</v-btn>
                </div>
                <v-btn v-else block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('shared.save')}}</v-btn>
            </v-form>
                        
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>      



      <!-- <div id="ga_20278199"> </div><div style="text-align:right; width:300px; padding:5px 0;">
        <img src="https://bcdn.grmtas.com/images/healthyads-logo.png" alt="logo" style="float:right; border:none;" />
        <div style="width:auto; padding:1px 5px 0 0; float:right; display:inline-block; font-family:Verdana, Geneva, sans-serif; font-size:11px; color:#333;">
            <a href="https://www.healthyads.com" target="_blank" title="Health Ads" style="text-decoration:none; color:#333;">Health Ads</a> by
        </div>
      </div> -->

    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import profileService from "@/services/profileService";
import activityService from "@/services/activityService";
import eventService from "@/services/eventService";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import Header from './_Header.vue';
import VueMarkdown from '@/components/VueMarkdown.vue'
import ToggleButtonInput from "@/components/ToggleButtonInput";

import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ManualActivityEntry",
  components: {
    VueMarkdown,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    Header,
    ToggleButtonInput,
  },
  props: {
  },
  data() {
    return {
      activityService: activityService,
      self: this,
      event: null,
      step: 1,
      selectedActivityTypeView: 'ALL',
      selectedActivityType: null,
      allowedActivityTypes: null,
      activity: null,
      profile: null,
      context: null,
      showClaimEvents: false,
      availableActivityTypes: ['_ALL'],
      forceShowAllActivityTypes: false,
      geoRestrictedEvents: null,
      approvedRaces: null,
      timeZoneOlson: null,
      error: null,
      valid: true,
      siteData: siteData,
      validationError: false,
      loading: true,
      infoMessage: null,
      addAnotherAfterSave: false,
      numberRules: [
        () => activityService.validateDistance(this.activity),//(this.activity.dist > 0 || this.activity.type === 'OTHER' || this.activity.type === 'YOGA' || this.activity.type === 'INDOOR_CARDIO' || this.activity.type === 'INDOOR_CYCLING') || "Please enter a positive number",
      ],
      stepsRules: [
        () => activityService.validateSteps(this.activity),
      ],
      breadcrumbItems: [
        { text: this.$t('profile.title'), href: '/profile' },
        { text: this.$t('profile.activities.title'), href: '/profile/activities' },
        { text: this.$t('profile.activities.entry.title'), disabled: true },
      ],
    };
  },
  async mounted() {
    this.timeZoneOlson = DateTime.now().zoneName;

    const id = this.$route.query.id;
    if (id) {
      var model = (await activityService.get(id)).data;
      console.log('Activity loaded', model);
      if (model) {
        this.activity = {
          id: id,
          start: model.start,
          type: model.type,
          dist: model.dist,
          elevation_gain: model.elevation_gain,
          elevation_loss: model.elevation_loss,
          kcal: model.kcal,
          steps: model.steps,
          custom: model.custom,
          time_s: model.time_s,
        }
      }
    }
    const eventId = this.$route.query.eventId;
    if (eventId) {
      this.event = (await eventService.get(eventId)).data;
      if (this.event) {
        if (this.event.activity_types) {
          this.selectedActivityTypeView = 'EVENT';
          if (this.event.activity_mode === 'STEP_BASED' && this.event.conversion_mode === 'AUTO_CONVERSIONS') {
            // step only
            this.allowedActivityTypes = this.globalAvailableActivityTypes.filter(x => [ 'RUNNING', 'WALKING', 'DAILY_SUMMARY' ].some(y => y == x.type));
          }
          else if (this.event.activity_mode === 'STEP_BASED' && this.event.activity_types.length === 0) {
            // No activities allowed, only daily steps
            this.allowedActivityTypes = this.globalAvailableActivityTypes.filter(x => [ 'DAILY_SUMMARY' ].some(y => y == x.type));
          }
          else {
            this.allowedActivityTypes = this.globalAvailableActivityTypes.filter(x => this.event.activity_types.some(y => y == x.type));
          }
          if (this.event.activity_types.some(x => x === 'CUSTOM') && this.event.custom_activity_types) {
            this.allowedActivityTypes = [...this.allowedActivityTypes, ...this.event.custom_activity_types];
          }
        }
      }
      console.log('/// activity filtered', this.allowedActivityTypes, 'global list', this.globalAvailableActivityTypes);
    }
    if (this.$route.query.copyFromId) {
      var model = (await activityService.get(this.$route.query.copyFromId)).data;
      if (model) {
        this.activity = model;
        this.activity.id = null;
        this.activity.start = null;
      }
    }
    if (!this.activity) {
      this.createNewActivity();
      if (this.$route.query.start) {
        this.activity.start = DateTime.fromISO(this.$route.query.start).startOf('day').toISO();
      }
      if (this.$route.query.duration) {
        this.activity.time_s = parseInt(this.$route.query.duration);
      }
      if (this.$route.query.distance) {
        this.activity.dist = parseInt(this.$route.query.distance);
      }
      if (this.$route.query.type) {
        this.activity.type = this.$route.query.type;
      }
      if (this.$route.query.customActivityTypeKey) {
        this.activity.custom_activity_type_key = this.$route.query.customActivityTypeKey;
      }
    }
    if (this.activity.type) {
    //console.log('// find in ', this.activity.type, this.globalAvailableActivityTypes);
      this.selectedActivityType = this.globalAvailableActivityTypes.find(x => x.type == this.activity.type);
      if (this.activity.type == 'CUSTOM' && this.activity.custom_activity_type_key && this.event && this.event.custom_activity_types) {
        this.selectActivityType(this.event.custom_activity_types.find(x => x.key == this.activity.custom_activity_type_key));
        //this.selectedActivityType = this.event.custom_activity_types.find(x => x.key == this.activity.custom_activity_type_key);
      }
      this.step = 2;
    }
    await this.loadData();
    console.log('Initiated manual entry', this.activity.start, this.activity.time_s, this.event);
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
    this.loading = false;

  },
  methods: {
    createNewActivity() {
      this.activity = {
        start: DateTime.now().startOf('day').toISO(),
        type: null,
        time_s: 0,
        steps: 0,
      };
    },
    getTodayDate() {
      var date = new Date();
      date.setHours(0,0,0,0);
      return date;
    },

    async loadData() {
      if (this.user && !this.profile) {
        this.loading = true;
        this.profile = (await profileService.get(/*full:*/false)).data;
        if (!this.event) {
          this.context = (await activityService.getActivityEntryContext()).data;
          console.log('this.availableActivityTypes', this.context);
          this.allowedActivityTypes = this.globalAvailableActivityTypes.filter(x => this.context.activity_types.some(y => y == x.type));
          if (this.context.activity_types.some(x => x === 'CUSTOM') && this.context.custom_activity_types) {
            this.allowedActivityTypes = [...this.allowedActivityTypes, ...this.context.custom_activity_types];
          }
          if (this.allowedActivityTypes.length === 0) {
            this.selectedActivityTypeView = 'ALL';
            //console.log('this.allowedActivityTypes', this.allowedActivityTypes);
          }
          else {
            this.selectedActivityTypeView = 'MY_EVENTS';
          }
        }
        /*
        this.availableActivityTypes = this.context.activity_types;
        if (this.showAllActivityTypes) {
          this.activity.type = this.globalAvailableActivityTypes.filter(x => x.core)[0].type;
        }
        else if (this.availableActivityTypes && this.availableActivityTypes.length) {
          this.activity.type = this.availableActivityTypes[0];
        }*/
        /*if (this.availableActivityTypes && this.availableActivityTypes.length && this.availableActivityTypes[0] != '_ALL') {
          // preselect the first available type
          this.activity.type = this.availableActivityTypes[0];
        }*/
        //console.log('this.availableActivityTypes', this.context);
        this.loading = false;
      }
    },

    selectActivityType(item) {
      console.log('/ selectActivityType',item);
      this.selectedActivityType = item;
      this.activity.type = item.type || 'CUSTOM';
      if (item.key) {
        this.activity.custom_activity_type_key = item.key;
        this.activity.name = item.name;
      }
      this.step ++;
    },

    async submit(ignoreValidation) {
      if (this.$refs.form.validate()) {
        this.error = null;
        this.infoMessage = null;
        this.validationError = false;


        try {
          //var validation = (await activityService.validate(this.activity)).data;
          console.log('Storing manual entry', this.activity);
          var model = {
            approved_race_ids: this.approvedRaces,
            ...this.activity,
          };
          var data = (await activityService.manualEntry(model, ignoreValidation, this.event && this.event.id)).data;
          if (data.status === "OK"){
            if (this.addAnotherAfterSave) {
              this.$refs.form.resetValidation();
              let type = this.activity.type;
              let customTypeKey = this.activity.custom_activity_type_key;
              this.createNewActivity();
              this.activity.type = type;
              this.activity.custom_activity_type_key = customTypeKey;
              this.addAnotherAfterSave = true;
              this.$helpers.toastResponse(this, {status: 'OK'}, this.$t('profile.activities.entry.uploaded-success'));
            } else {
              this.$router.push({ name: 'activity', params: { id: data.id } });
            }
          }
          else if (data.status === "INFO") {
            this.infoMessage = data.msg;
            this.validationError = true;

            if (data.id === "GEO_MISSING") {
              this.geoRestrictedEvents = (await profileService.getGeoRestrictedEvents(this.activity)).data.data;
              console.log('geoRestrictedEvents', this.geoRestrictedEvents);
              console.log('geoRestrictedRaces', this.geoRestrictedRaces);
            }
          }
          else {
            this.error = data.msg;
            this.validationError = true;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

    
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    visibleActivityTypes() {
      if (this.selectedActivityTypeView == 'EVENT' || this.selectedActivityTypeView == 'MY_EVENTS') {
        return this.allowedActivityTypes;
      }
      return this.globalAvailableActivityTypes;
    },
    globalAvailableActivityTypes() {
      if (tenant.activityTypes) {
        return siteData.activities.filter(x => tenant.activityTypes.hasOwnProperty(x.type))
      }
      return siteData.activities;
    },
    showAllActivityTypes() {
      return this.forceShowAllActivityTypes || !this.availableActivityTypes || this.availableActivityTypes.length == 0 || this.availableActivityTypes.some(x => x === '_ALL') || this.availableActivityTypes.length > 5;
    },
    showCustomField() {
      if (this.selectedActivityType && (['VALUE_ONLY', 'TIME_VALUE'].some(x => x === this.selectedActivityType.track_mode))) {
        return true;
      }
      return this.activity && (['VOLUNTEERING', 'OTHER'].some(x => x === this.activity.type)) && tenant.id != '100kmc';
    },
    showNameField() {
      return this.activity && (['DAILY_SUMMARY'].some(x => x === this.activity.type));
    },
    customFieldLabel() {
      if (this.selectedActivityType) {
        return this.selectedActivityType.value_label;
      }
      return null;
      //return this.showCustomField && this.event.races.filter(x => x.scoring == 'CUSTOM')[0].custom;
    },
    timeFieldLabel() {
      return this.$t('profile.activities.entry.duration');
    },
    geoRestrictedRaces() {
      return this.geoRestrictedEvents == null ? [] : this.geoRestrictedEvents.reduce((acc, item) => {
        const races = item.races.map(x => ({ id: `${item.id}_${x.id}`, name: `${item.name}: ${x.name}`}));
        return [...acc, ...races];
      }, []);
    },
    unitType() {
      return this.profile == null || (['ROWING', 'SWIMMING'].some(x => x === this.activity.type))  ? 'METRIC' : this.profile.unit;
    },
    unitTypeDiplay() {
      return this.unitType == 'METRIC' ? 'km' : 'mi';
    },
    elevationUnitTypeDiplay() {
      return this.unitType == 'METRIC' ? 'm' : 'ft';
    },
    isDownhill() {
      return this.activity && (this.activity.type == 'ALPINE_SKIING' || this.activity.type == 'SNOWBOARDING');
    },
    showDistance() {
      if (tenant.id == '100kmc') {
        return true;
      }
      return this.activity && !activityService.zeroDistanceActivities(this.activity);
    },
    showDuration() {
      if (this.selectedActivityType && this.selectedActivityType.track_mode === 'VALUE_ONLY') {
        return false;
      }
      return true;
    },
    showSteps() {
      return this.activity && ['DAILY_SUMMARY'].some(x => x === this.activity.type);
    },
    showElevation() {
      return this.activity && !['SWIMMING'].some(x => x === this.activity.type) && this.showDistance;
    },
    showElevationLoss() {
      return this.activity && (this.isDownhill || this.activity.type == 'CYCLING');
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    computedElevationGain:{
      get () {
        return this.activity ? this.activity.elevation_gain : null
      },
      set (value) {
        this.activity.elevation_gain = value === /*must be triple!*/ 0 ? null /* store 0 as null */ : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },
    computedElevationLoss:{
      get () {
        return this.activity ? this.activity.elevation_loss : null
      },
      set (value) {
        this.activity.elevation_loss = value === /*must be triple!*/ 0 ? null /* store 0 as null */ : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },

  },

};
</script>
<style lang="scss">
</style>

